<template>
    <div class="caseDetail">
        <headComp></headComp>
        <breadComp></breadComp>

        <div class="centerWrapper">
            <div class="richText">
                <!-- <div class="title">现代客厅装修样本 定制城市与家的格调</div>
                <div class="info">
                    <div>浏览数：3.9K</div>
                    <div>作者：芮迪设计</div>
                </div> -->
                <div class="textContainer">{{buyersShowInfo.content}}</div>
                <div class="imgList">
                    <img :src="img.url" v-for="(img,i) in buyersShowInfo.images" :key="i">
                </div>
            </div>
            <div class="relaMerchandise">
                <div class="areaName">相关商品</div>
                <div class="list">
                    <div class="item" v-for="(item,i) in buyersShowInfo.goodsInfoList" :key="i" @click="clickGoods(item)">
                        <img :src="item.cover?item.cover:require('../assets/imgs/place11.png')"/>
                        <div class="price"><span>￥</span>{{item.ourShopPrice}}</div>
                        <div class="intro">
                            <span class="tag">【{{item.brandName}}】</span>
                            {{item.name}} 
                        </div>
                        <div class="rela">
                            <div>
                                <img src="../assets/imgs/solded.png"/>
                                <span class="num">{{item.sales+item.virtualSales}}</span>
                                <span>已售</span>
                            </div>
                            <div>
                                <img src="../assets/imgs/heat.png"/>
                                <span class="num">{{item.hotValue}}</span>
                                <span>热度</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buyersShow">
                <div class="areaName">相似买家秀</div>
                <div class="list">
                    <!-- <div class="item" v-for="i in 3" :key="i" @click="toDetail">
                        <img src="../assets/imgs/place34.png"/>
                        <div class="name">天然原木风打造舒缓的现代极简客厅内饰</div>
                        <div class="info">
                            <span>2023-12-15  16:02</span>
                            <span>
                                <img src="../assets/imgs/effect13.png"/>
                                1.7W
                            </span>
                        </div>
                    </div> -->

                    <div class="item" v-for="(item,i) in similarList" :key="i" @click="toDetail(item)">
                        <!-- <img src="../assets/imgs/place34.png"/> -->
                        <div class="img">
                            <img :src="item.images[0].url"/>
                            <div class="user" v-if="item.userInfo">
                                <img :src="item.userInfo.avatar"/>
                                <span>{{item.userInfo.nickName||item.userInfo.telephone}}</span>
                            </div>
                        </div>
                        <div class="name">{{item.content}}</div>
                        <div class="info">
                            <span>{{item.createTime}}</span>
                            <span>
                                <img src="../assets/imgs/effect13.png"/>
                                {{item.readCount}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import {getBuyersShowInfo__,getSimilarBuyersShowTypeList} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp
    },
    data(){
        return{
            buyersShowInfo:{},
            similarList:[],//相似买家秀
        }
    },
    methods: {
        gainBuyersShowInfo(){
            getBuyersShowInfo__({
                buyersShowId:this.$route.query.buyersShowId
            }).then(res=>{
                // console.log("买家秀详情",res);
                if(res.code==200){
                    this.buyersShowInfo=res.data;
                }
            })
        },
        // 点击商品
        clickGoods(item){
            this.$router.push({
                path:'/furnitureDetail',
                query:{goodsId:item.goodsId}
            })
        },
        // 获取相似买家秀
        gainSimilarShows(){
            getSimilarBuyersShowTypeList({buyersShowId:this.$route.query.buyersShowId}).then(res=>{
                // console.log("相似买家秀",res);
                if(res.code==200){
                    this.similarList=res.data
                }
            })
        },
        // 点击相似买家秀
        toDetail(item){
            this.$router.push({
                path:"/caseDetail",
                query:{buyersShowId:item.buyersShowId}
            })
            this.$nextTick(()=>{
                location.reload()
            })
        },
    },
    created() {
        this.gainBuyersShowInfo();
        this.gainSimilarShows();
    },
}
</script>

<style lang="less" scoped>
.caseDetail{
    .richText{
        margin-top: 50px;
        padding: 20px;
        .title{
            font-size: 40px;
            font-weight: 500;
            color: #000;
            text-align: center;
        }
        .info{
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            &>div{
                width: 160px;
                font-size: 20px;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
            }
        }
        .textContainer{
            margin-top: 30px;
            /deep/img{
                max-width: 100%;
            }
        }
        .imgList{
            img{
                width: 100%;
                margin-top: 50px;
            }
        }
    }
    .relaMerchandise{
        margin-top: 100px;
        .areaName{
            font-size: 20px;
            font-weight: 700;
            color: #333;
        }
        .list{
            // margin-top: 40px;
            display: flex;
            flex-wrap: wrap;
            .item{
                width: 337px;
                margin-top: 40px;
                margin-right: 40px;
                cursor: pointer;
                &:nth-child(4n){
                    margin-right: 0;
                }
                &>img{
                    width: 100%;
                    height: 252px;
                }
                .price{
                    font-size: 22px;
                    font-weight: 700;
                    color: rgba(200, 64, 57, 1);
                    margin-top: 13px;
                    &>span{
                        font-size: 14px
                    }
                }
                .intro{
                    font-size: 13px;
                    font-weight: 700;
                    letter-spacing: -0.7px;
                    line-height: 20px;
                    color: rgba(56, 56, 56, 1);
                    margin-top: 6px;
                    height: 40px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp:2;
                    line-clamp:2;
                    .tag{
                        font-size: 13px;
                        font-weight: 500;
                        letter-spacing: 0px;
                        line-height: 20px;
                        color: rgba(158, 110, 62, 1);
                    }
                }
                .rela{
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    &>div{
                        display: flex;
                        align-items: center;
                        font-size: 13px;
                        font-weight: 700;
                        color: rgba(153, 153, 153, 1);
                        margin-right: 15px;
                        padding-right: 15px;
                        border-right: 1px solid rgba(237, 237, 237, 1);
                        &:last-child{
                            border-right: none;
                            margin-right: 0;
                        }
                        .num{
                            font-size: 12px;
                            font-weight: 700;
                            color: rgba(230, 77, 0, 1);
                            margin: 0 4px;
                        }
                    }
                }
            }
        }
    }
    .buyersShow{
        margin-top: 100px;
        .areaName{
            font-size: 20px;
            font-weight: 700;
            color: #333;
        }
        .list{
            margin-top: 40px;
            display: flex;
            flex-wrap: wrap;
            .item{
                width: 460px;
                margin-right: 30px;
                margin-right: 45px;
                margin-bottom: 48px;
                &:nth-child(3n){
                    margin-right: 0;
                }
                cursor: pointer;
                &:hover{
                    .img>img{
                        width: calc(100% + 20px);
                        height: calc(100% + 20px);
                    }
                }
                &>.img{
                    width: 100%;
                    height: 370px;
                    border-radius: 10px;
                    overflow: hidden;
                    position: relative;
                    &>img{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        transition: all 0.3s;
                        object-fit: cover;
                    }
                    .user{
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        // background: rgba(255, 255, 255, 0.3);
                        width: 100%;
                        height: 82px;
                        border-radius: 0px 0px 10px 10px;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
                        display: flex;
                        align-items: center;
                        padding: 0 15px;
                        &>img{
                            width: 46px;
                            height: 46px;
                            border-radius: 50%;
                        }
                        &>span{
                            margin-left: 10px;
                            font-size: 20px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }
                .name{
                    margin-top: 20px;
                    font-size: 20px;
                    font-weight: 700;
                    // line-height: 37.65px;
                    // height: 74px;
                    line-height: 30px;
                    height: 60px;
                    color: rgba(56, 56, 56, 1);
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .info{
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 500;
                    color: rgba(56, 56, 56, 1);
                    &>span:nth-child(2){
                        display: flex;
                        align-items: center;
                        img{
                            margin-right: 4px;
                            width: 28px;
                            height: 21px;
                        }
                    }
                }
            }
        }
    }
}
</style>